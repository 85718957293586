import React, { FC, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { PageContext, pages } from "../context/page-context";
import BlockText from "../components/content/block-text";
import PageLayout from "../components/layout/page-layout";
import headingDivider from "../images/svg/HeadingDivider_1.svg";

import style from "../styles/page-styles/technical-page.module.scss";

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  // eslint-disable-next-line quotes
  require("smooth-scroll")('a[href*="#"]');
}

const TechnicalPage: FC<ITechPage> = ({ data }: ITechPage) => {
  const { sanityTechnicalInfo: tech } = data;
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.technical);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <header className={`${style.header}`}>
        <div className={style.titleImgContainer}>
          <Img className={style.headerMainImage} fluid={tech.mainImage.asset.fluid} />
          <Img className={`${style.headingImg2}`} fluid={tech.titleImage_1.asset.fluid} />
          <Img className={`${style.headingImg3}`} fluid={tech.titleImage_2.asset.fluid} />
          <div className={style.titleText}>
            <h1>{tech.pageTitle}</h1>
            <div className={style.divider} />
          </div>
        </div>

        <div className={style.desktopTextContainer}>
          <div className={style.desktopTitleText}>
            <h1>{tech.pageTitle}</h1>
            <img className={style.desktopDivider} src={headingDivider} alt="Title divider" />
          </div>
          <div className={style.headerBody}>
            <BlockText blocks={tech._rawHeadingBody} />
          </div>
          <ul className={style.pageLinks}>
            <li>
              <a href="#section1">{tech.section1.title}</a>
              <div className={style.pageLinkDivider} />
            </li>
            <li>
              <a href="#section2">{tech.section2.title}</a>
              <div className={style.pageLinkDivider} />
            </li>
            <li>
              <a href="#section3">{tech.section3.title}</a>
              <div className={style.pageLinkDivider} />
            </li>
          </ul>
        </div>
      </header>

      <section className={style.section1} id="section1">
        <div className={style.section1ImgBackground}>
          <Img fluid={tech.section1.sectionImage.asset.fluid} />
        </div>
        <div className={style.sectionText}>
          <h2>{tech.section1.title}</h2>
          <BlockText blocks={tech._rawSection1.sectionBody} />
        </div>
      </section>

      <section className={style.section2} id="section2">
        <Img className={style.section2Img} fluid={tech.section2.sectionImage.asset.fluid} />
        <div className={style.sectionText}>
          <h2>{tech.section2.title}</h2>
          <BlockText blocks={tech._rawSection2.sectionBody} />
        </div>
      </section>

      <section className={style.section3} id="section3">
        <div className={style.section3ImgBackground}>
          <Img fluid={tech.section3.sectionImage.asset.fluid} />
        </div>
        <div className={style.sectionText}>
          <h2>{tech.section3.title}</h2>
          <BlockText blocks={tech._rawSection3.sectionBody} />
        </div>
      </section>
    </PageLayout>
  );
};

export default TechnicalPage;

interface IImage {
  asset: {
    id: string;
    fluid: any;
  };
  crop: any;
  hotspot: any;
}

interface ISection {
  sectionImage: IImage;
  title: string;
}

interface ITechPage {
  data: {
    sanityTechnicalInfo: {
      pageTitle: string;
      _id: string;
      _rawHeadingBody: any;
      _rawSection1: any;
      _rawSection2: any;
      _rawSection3: any;
      section1: ISection;
      section2: ISection;
      section3: ISection;
      mainImage: IImage;
      titleImage_1: IImage;
      titleImage_2: IImage;
    };
  };
}

export const technicalPageQuery = graphql`
  query technicalQuery {
    sanityTechnicalInfo {
      pageTitle
      _id
      _rawHeadingBody
      _rawSection1
      _rawSection2
      _rawSection3
      mainImage {
        asset {
          id
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
        crop {
          _key
          bottom
          _type
          left
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
      section1 {
        sectionImage {
          asset {
            id
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
          crop {
            _key
            bottom
            _type
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        title
      }
      section2 {
        sectionImage {
          asset {
            id
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
          crop {
            _key
            bottom
            _type
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        title
      }
      section3 {
        title
        sectionImage {
          asset {
            id
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
          crop {
            _key
            bottom
            _type
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
      }
      titleImage_1 {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
          id
        }
        crop {
          _key
          _type
          left
          bottom
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
      titleImage_2 {
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
        crop {
          _key
          _type
          bottom
          left
          right
          top
        }
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
          id
        }
      }
    }
  }
`;
